// Libraries
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from 'react-datepicker';
import {Portal} from 'react-overlays';

// App
import {createStyledDOMInput} from '@supermove/styles';

// Assets
import 'react-datepicker/dist/react-datepicker.css';
import './DateInput.css';

const StyledDatePicker = createStyledDOMInput(DatePicker);

/**
 * Normally react-popper will place the date picker popover in the same constraints
 * as the parent div (i.e. scrollable div).
 * isPortaled creates a container outside of the constrained parent container.
 * This way the date picker popover is able to be above other parent elements
 * without the use of z-index or overflow.
 */
const CalendarContainer = ({children}) => {
  const element = document.getElementById('calendar-portal');

  return <Portal container={element}>{children}</Portal>;
};

const DateInput = ({
  disabled,
  required,
  isPastDisabled,
  isFutureDisabled,
  name,
  isPortaled,
  isEnabledFlip,
  offset,
  position,
  placeholder,
  value,
  minDate,
  maxDate,
  setFieldValue,
  onChangeDate,
  style,
  customInput,
  excludeDates,
  autoFocus,
}) => (
  <StyledDatePicker
    disabled={disabled}
    customInput={customInput}
    required={required}
    minDate={isPastDisabled ? new Date() : minDate}
    maxDate={isFutureDisabled ? new Date() : maxDate}
    placeholderText={placeholder}
    popperContainer={isPortaled ? CalendarContainer : null}
    popperPlacement={position}
    popperModifiers={{
      offset: {
        offset,
      },
      flip: {
        enabled: isEnabledFlip,
      },
      preventOverflow: {
        enabled: true,
        escapeWithReference: true,
      },
    }}
    selected={value === '' ? undefined : value} // HACK(mark): This is fixed in react-datepicker >1.8.0.
    onChange={(date) => {
      // `date` may come back null but we want an empty string instead of null.
      setFieldValue(name, date || '');
      onChangeDate(date);
    }}
    style={style}
    excludeDates={excludeDates}
    autoFocus={autoFocus}
  />
);

// --------------------------------------------------
// Props
// --------------------------------------------------
DateInput.propTypes = {
  position: PropTypes.string,
  isPortaled: PropTypes.bool,
  offset: PropTypes.string,
  onChangeDate: PropTypes.func,
  isEnabledFlip: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

DateInput.defaultProps = {
  position: 'bottom-start',
  isPortaled: false,
  offset: '0px, 0px',
  onChangeDate: () => {},
  isEnabledFlip: false,
  autoFocus: false,
};

export default DateInput;
