// Libraries
import {useState} from '@supermove/hooks';

type Args = {
  url: string;
};

const useFormspreeForm = ({url}: Args) => {
  const [submitting, setSubmitting] = useState<boolean>();
  const [error, setError] = useState<Error>();
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (values: any) => {
    try {
      setSubmitting(true);
      await fetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setSuccess(true);
    } catch (error) {
      setError(error as Error);
    }

    setSubmitting(false);
  };

  return {
    submitting,
    success,
    error,
    handleSubmit,
  };
};

export default useFormspreeForm;
