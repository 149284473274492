// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import WindowedSelect, {
  createFilter,
  components as ReactWindowedSelectComponents,
} from 'react-windowed-select';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, INPUT_BORDER_COLOR, Typography} from '@supermove/styles';

const controlStyle = {
  height: 40,
  borderRadius: 3,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: INPUT_BORDER_COLOR,
};

const optionStyle = {
  display: 'block',
  lineHeight: '19px',
  minHeight: '35px',
  fontWeight: 500,
  marginTop: '2px',
  marginBottom: '2px',
  cursor: 'pointer',
};

const placeholderStyle = {
  fontFamily: 'Avenir',
  lineHeight: '19px',
  fontWeight: 500,
  color: colors.gray.tertiary,
};

const singleValueStyle = {
  fontFamily: 'Avenir',
  fontWeight: 500,
};

const Row = Styled.View`
  flex-direction: row;
`;

const OptionContainer = Styled.View`
  background-color: ${({color}) => color};
  padding-vertical: 5px;
  padding-horizontal: 12px;
`;

const OptionLabel = Styled.Text`
  ${Typography.Body}
  color: ${({color}) => color};
`;

const OptionDescription = Styled.Text`
  ${Typography.Micro}
  color: ${({color}) => color};
`;

const getOptionColor = ({isSelected, isFocused}) => {
  if (isSelected) {
    return colors.blue.interactive;
  }
  if (isFocused) {
    return colors.hover;
  }
  return 'transparent';
};

const getBackgroundColor = ({disabled, required, backgroundColor}) => {
  if (disabled) {
    return colors.gray.disabled;
  }
  if (required) {
    return colors.alpha(colors.yellow.hover, 0.1);
  }
  if (backgroundColor) {
    return backgroundColor;
  }
  return colors.white;
};

const getSingleValueColor = ({state}) => {
  const {data} = state;
  if (data.color) {
    return data.color;
  }
  return colors.gray.primary;
};

// NOTE(cooper): Discreet is a special dropdown style,
// in the future we may want to move this into it's own design system file
const getDiscreetControlStyle = ({state, placeholder, isHovered}) => {
  const baseDiscreetControlStyle = {
    height: 25,
    minHeight: 20,
    maxWidth: 135,
    borderWidth: 0,
    ...(isHovered || state.isFocused
      ? {
          boxShadow: '0 0 0 1px #2684ff',
          paddingLeft: 5,
          paddingRight: 2,
        }
      : {}),
  };

  if (state.hasValue) {
    const selectedValue = state.getValue()[0];
    return {
      ...baseDiscreetControlStyle,
      width: `${10 * selectedValue.label.length + 20}px`,
    };
  }

  // If no value exists, use the width of the placeholder
  return {
    ...baseDiscreetControlStyle,
    width: `${8 * placeholder.length + 20}px`,
  };
};

const OptionWithDescription = ({
  data: {label, description},
  value,
  innerProps,
  isFocused,
  isSelected,
  options,
}) => {
  const isFirstOption = value === _.get(options, `0.value`);
  const isLastOption = value === _.get(options, `${options.length - 1}.value`);
  return (
    <OptionContainer
      {...innerProps}
      isFocused={isFocused}
      isFirstOption={isFirstOption}
      isLastOption={isLastOption}
      color={getOptionColor({isSelected, isFocused})}
    >
      <Space height={isFirstOption ? 4 : 2} />
      <Row>
        <OptionLabel numberOfLines={1} color={isSelected ? colors.white : colors.gray.primary}>
          {label}
        </OptionLabel>
      </Row>
      <Row>
        <OptionDescription
          numberOfLines={1}
          color={isSelected ? colors.gray.disabled : colors.gray.secondary}
        >
          {description}
        </OptionDescription>
      </Row>
      {isLastOption && <Space height={4} />}
    </OptionContainer>
  );
};

const DropdownInputVirtualized = ({
  innerRef,
  disabled,
  required,
  isClearable,
  isLoading,
  isSearchable,
  isDiscreet,
  isHovered,
  name,
  placeholder,
  menuPlacement,
  value,
  inputValue,
  tabSelectsValue,
  options,
  noOptionsMessage,
  filterOption,
  components,
  onInputChange,
  onChangeValue,
  onFocus,
  onBlur,
  onMenuOpen,
  onMenuClose,
  setFieldValue,
  style,
  containerStyleOverride,
  valueContainerStyle,
  inputStyle,
  selectionStyle,
  backgroundColor,
  fontSize,
  optionHeight,
  menuWidth,
}) => {
  const responsive = useResponsive();
  return (
    <WindowedSelect
      ref={innerRef}
      isClearable={isClearable}
      isDisabled={disabled}
      isSearchable={isSearchable}
      name={name}
      isLoading={isLoading}
      placeholder={placeholder}
      menuShouldScrollIntoView={false}
      menuPlacement={menuPlacement}
      value={_.find(options, (option) => option.value === value) || ''}
      tabSelectsValue={tabSelectsValue}
      inputValue={inputValue}
      options={options}
      noOptionsMessage={noOptionsMessage}
      filterOption={filterOption}
      onFocus={onFocus}
      onBlur={onBlur}
      onInputChange={onInputChange}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      onChange={(option) => {
        const prevValue = value;
        const newValue = option ? option.value : null;
        setFieldValue(name, newValue);
        // TODO(mark): Hack to avoid the Formik name: onChange.
        onChangeValue && onChangeValue(newValue, option, prevValue);
      }}
      components={{
        ...(isClearable && value ? {} : {IndicatorSeparator: () => null}),
        ClearIndicator: (props) => {
          return (
            <ReactWindowedSelectComponents.ClearIndicator {...props}>
              <Icon
                source={Icon.Xmark}
                size={16}
                color={colors.gray.primary}
                style={{
                  marginLeft: 4,
                  marginRight: 4,
                  marginTop: 2,
                  cursor: disabled ? 'default' : 'pointer',
                }}
              />
            </ReactWindowedSelectComponents.ClearIndicator>
          );
        },
        Option: (props) => {
          // Use menuLabel to customize menu option text
          return (
            <ReactWindowedSelectComponents.Option {...props}>
              {props.data.menuLabel || props.data.label}
            </ReactWindowedSelectComponents.Option>
          );
        },
        ...components,
      }}
      styles={{
        container: (current, state) => ({
          ...current,
          pointerEvents: 'auto',
          cursor: state.isDisabled ? 'not-allowed' : 'text',
        }),
        control: (current, state) => {
          // Use containerStyleOverride instead of style if you want to override all the default styling
          const baseStyle = containerStyleOverride || current;
          return {
            ...baseStyle,
            '&:hover': {
              borderColor: disabled
                ? INPUT_BORDER_COLOR
                : state.isFocused
                ? colors.blue.interactive
                : colors.blue.hover,
            },
            ...(containerStyleOverride ? {} : {...controlStyle, ...style}),
            ...(state.isFocused ? {borderColor: colors.blue.interactive} : {}),
            ...(isDiscreet ? {...getDiscreetControlStyle({state, placeholder, isHovered})} : {}),
            'pointerEvents': state.isDisabled ? 'none' : 'auto',
            'cursor': disabled ? 'default' : 'text',

            // Custom 'required' feature to change the background-color.
            'backgroundColor': getBackgroundColor({
              disabled,
              required,
              backgroundColor,
            }),
          };
        },
        valueContainer: (current) => ({
          ...(valueContainerStyle || current),
          ...(isDiscreet ? {paddingLeft: '0px'} : {}),
        }),
        placeholder: (current) => ({
          ...current,
          ...placeholderStyle,
          ...(isDiscreet ? {marginLeft: '0px'} : {}),
          fontSize,
        }),
        option: (current) => ({
          ...current,
          ...optionStyle,
          fontSize,
          height: optionHeight,
        }),
        menu: (current) => {
          const styles = {
            ...current,
            display: 'block',
            overflow: 'hidden',
            width: menuWidth || '100%',
          };
          // The flex 1 below ensures the options take the full width of the menu container
          styles['& div'] = {flex: 1};
          return styles;
        },
        menuList: (current) => ({
          ...current,
          display: 'block',
          paddingTop: 2,
          paddingBottom: 2,
        }),
        singleValue: (current, state) => ({
          ...current,
          ...singleValueStyle,
          color: getSingleValueColor({state}),
          ...selectionStyle,
          ...(isDiscreet ? {marginLeft: '0px'} : {}),
          fontSize,
        }),
        dropdownIndicator: (current, state) => ({
          ...current,
          ...(isDiscreet
            ? {
                padding: '0px',
                display: 'none',
                ...(isHovered || state.isFocused ? {display: 'flex'} : {}),
              }
            : {}),
        }),
        indicatorSeparator: (current) => ({
          ...current,
          ...(isDiscreet ? {display: 'none'} : {}),
        }),
        input: (current, state) => ({
          ...(inputStyle
            ? {position: !state.value ? 'absolute' : 'relative', ...inputStyle}
            : current),
          width: '100%',
          input: {
            // On mobile, setting the width of the inner input to 100% enables copy paste.
            // On desktop, this causes problems by preventing the text from filling the
            // entire width of the input.
            width: responsive.mobile ? '100% !important' : null,
            textAlign: 'left',
            fontFamily: 'Avenir',
            fontWeight: 500,
          },
        }),
      }}
    />
  );
};

DropdownInputVirtualized.createFilter = createFilter;
DropdownInputVirtualized.OptionWithDescription = OptionWithDescription;

// --------------------------------------------------
// Props
// --------------------------------------------------
DropdownInputVirtualized.propTypes = {
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isHovered: PropTypes.bool,
  isDiscreet: PropTypes.bool,
  tabSelectsValue: PropTypes.bool,
  selectionStyle: PropTypes.object,
  valueContainerStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  fontSize: PropTypes.number,
  menuPlacement: PropTypes.string,
  optionHeight: PropTypes.number,
  menuWidth: PropTypes.string,
};

DropdownInputVirtualized.defaultProps = {
  isClearable: false,
  isSearchable: true,
  isHovered: false,
  isDiscreet: false,
  tabSelectsValue: false,
  selectionStyle: {},
  valueContainerStyle: null,
  inputStyle: null,
  fontSize: 14,
  menuPlacement: 'bottom',
  optionHeight: 35,
  menuWidth: null,
};

export default DropdownInputVirtualized;
