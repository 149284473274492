// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Select, {createFilter, components as ReactSelectComponents} from 'react-select';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useMountEffect, useResponsive} from '@supermove/hooks';
import {colors, INPUT_BORDER_COLOR, Typography} from '@supermove/styles';

const controlStyle = {
  borderRadius: 4,
  borderWidth: 1,
  borderStyle: 'solid',
  boxShadow: 'none',
  borderColor: INPUT_BORDER_COLOR,
};

const defaultOptionStyle = {
  display: 'block',
  lineHeight: '19px',
  minHeight: '35px',
  fontWeight: 500,
  marginTop: '2px',
  marginBottom: '2px',
  cursor: 'pointer',
};

const defaultPlaceholderStyle = {
  fontFamily: 'Avenir',
  lineHeight: '19px',
  fontWeight: 500,
  color: INPUT_BORDER_COLOR,
};

const singleValueStyle = {
  fontFamily: 'Avenir',
  fontWeight: 500,
};

const Row = Styled.View`
  flex-direction: row;
`;

const OptionLabel = Styled.Text`
  ${Typography.Body}
  color: ${({color}) => color};
`;

const OptionDescription = Styled.Text`
  ${Typography.Micro}
  color: ${({color}) => color};
`;

const GroupContainer = Styled.View`
  flex: 1;
`;

const GroupLabelText = Styled.Text`
  ${Typography.Label}
  text-transform: capitalize;
`;

const GroupDivider = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const getBackgroundColor = ({disabled, required, backgroundColor}) => {
  if (disabled) {
    return colors.gray.disabled;
  }
  if (required) {
    return colors.alpha(colors.yellow.hover, 0.1);
  }
  if (backgroundColor) {
    return backgroundColor;
  }
  return colors.white;
};

const getSingleValueColor = ({state}) => {
  const {data} = state;
  if (data.color) {
    return data.color;
  }
  return colors.gray.primary;
};

// NOTE(cooper): Discreet is a special dropdown style,
// in the future we may want to move this into it's own design system file
const getDiscreetControlStyle = ({state, placeholder, isHovered}) => {
  const baseDiscreetControlStyle = {
    height: 25,
    minHeight: 20,
    maxWidth: 135,
    borderWidth: 0,
    ...(isHovered || state.isFocused
      ? {
          boxShadow: '0 0 0 1px #2684ff',
          paddingLeft: 5,
          paddingRight: 2,
        }
      : {}),
  };

  if (state.hasValue) {
    const selectedValue = state.getValue()[0];
    return {
      ...baseDiscreetControlStyle,
      width: `${10 * selectedValue.label.length + 20}px`,
    };
  }

  // If no value exists, use the width of the placeholder
  return {
    ...baseDiscreetControlStyle,
    width: `${8 * placeholder.length + 20}px`,
  };
};

const filterConfigForDescriptionInOption = {
  // This tells react-select what value to search options on
  stringify: (option) => {
    return `${option.label} ${option.data.description}`;
  },
};

const Label = ({isSelected, children}) => {
  return (
    <OptionLabel numberOfLines={1} color={isSelected ? colors.white : colors.gray.primary}>
      {children}
    </OptionLabel>
  );
};

const SecondaryLabel = ({children}) => {
  return <OptionLabel color={colors.gray.tertiary}>{children}</OptionLabel>;
};

const OptionWithDescription = ({data, ...props}) => {
  const {label, description} = data;
  return (
    <ReactSelectComponents.Option {...props}>
      <Row>
        <Label {...props}>{label}</Label>
      </Row>
      <Row>
        <OptionDescription
          numberOfLines={1}
          color={props.isSelected ? colors.gray.disabled : colors.gray.secondary}
        >
          {description}
        </OptionDescription>
      </Row>
    </ReactSelectComponents.Option>
  );
};

const OptionWithDescriptionOnRight = ({data, ...props}) => {
  const {label, description} = data;
  return (
    <ReactSelectComponents.Option {...props}>
      <Row>
        <Label {...props}>{label}</Label>
        <Space style={{flex: 1}} />
        <OptionLabel
          numberOfLines={1}
          color={props.isSelected ? colors.gray.disabled : colors.gray.secondary}
        >
          {description}
        </OptionLabel>
      </Row>
    </ReactSelectComponents.Option>
  );
};

const Option = ({...props}) => {
  // Use menuLabel when the menu option text is different from the input selection text
  const optionLabel = props.data && (props.data.menuLabel || props.data.label);
  return (
    <ReactSelectComponents.Option {...props}>
      {optionLabel || props.children}
    </ReactSelectComponents.Option>
  );
};

const GroupLabel = ({label}) => {
  return (
    <React.Fragment>
      <GroupLabelText>{label}</GroupLabelText>
      <Space height={8} />
    </React.Fragment>
  );
};

const handleChange = ({setFieldValue, name, newValue, option, prevValue, onChangeValue}) => {
  setFieldValue(name, newValue);
  // TODO(mark): Hack to avoid the Formik name: onChange.
  onChangeValue && onChangeValue(newValue, option, prevValue);
};

const DropdownInput = ({
  innerRef,
  autoFocus,
  disabled,
  required,
  isClearable,
  isLoading,
  isSearchable,
  isDiscreet,
  isHovered,
  isPortaled,
  isJoinedList,
  isSingleOptionSelected,
  name,
  placeholder,
  menuIsOpen,
  menuPlacement,
  value,
  selectedOption,
  inputValue,
  tabSelectsValue,
  options,
  noOptionsMessage,
  filterOption,
  components,
  onInputChange,
  onChangeValue,
  onFocus,
  onBlur,
  onMenuOpen,
  onMenuClose,
  setFieldValue,
  style,
  containerStyleOverride,
  valueContainerStyle,
  inputStyle,
  placeholderStyle,
  menuListStyle,
  selectionStyle,
  optionStyle,
  backgroundColor,
  fontSize,
  showDescriptionInOption,
  controlShouldRenderValue,
  DropdownIndicatorComponent,
}) => {
  const responsive = useResponsive();

  useMountEffect(() => {
    if (isSingleOptionSelected && _.size(options) === 1) {
      const option = options[0];
      const prevValue = null;
      const newValue = option ? option.value : null;
      handleChange({setFieldValue, name, newValue, prevValue, option, onChangeValue});
    }
  });

  return (
    <Select
      ref={innerRef}
      autoFocus={autoFocus}
      isClearable={isClearable}
      isDisabled={disabled}
      isSearchable={isSearchable}
      name={name}
      isLoading={isLoading}
      placeholder={placeholder}
      menuIsOpen={menuIsOpen}
      menuPlacement={menuPlacement}
      menuPortalTarget={isPortaled && document.body}
      value={selectedOption || _.find(options, (option) => option.value === value) || ''}
      tabSelectsValue={tabSelectsValue}
      inputValue={inputValue}
      options={options}
      noOptionsMessage={noOptionsMessage}
      filterOption={
        filterOption ||
        (showDescriptionInOption ? createFilter(filterConfigForDescriptionInOption) : undefined)
      }
      onFocus={onFocus}
      onBlur={onBlur}
      onInputChange={onInputChange}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      onChange={(option) => {
        const prevValue = value;
        const newValue = option ? option.value : null;
        handleChange({setFieldValue, name, newValue, prevValue, option, onChangeValue});
      }}
      formatGroupLabel={GroupLabel}
      controlShouldRenderValue={controlShouldRenderValue}
      components={{
        ...(isClearable && value ? {} : {IndicatorSeparator: () => null}),
        ClearIndicator: (props) => {
          return (
            <ReactSelectComponents.ClearIndicator {...props}>
              <Icon
                source={Icon.Xmark}
                size={16}
                color={colors.gray.primary}
                style={{
                  marginLeft: 4,
                  marginRight: 4,
                  marginTop: 2,
                  cursor: disabled ? 'default' : 'pointer',
                }}
              />
            </ReactSelectComponents.ClearIndicator>
          );
        },
        ...(DropdownIndicatorComponent
          ? {
              DropdownIndicator: (props) => {
                return (
                  <ReactSelectComponents.DropdownIndicator {...props}>
                    <DropdownIndicatorComponent />
                  </ReactSelectComponents.DropdownIndicator>
                );
              },
            }
          : {
              DropdownIndicator: (props) => {
                return (
                  <ReactSelectComponents.DropdownIndicator {...props}>
                    <Icon
                      source={Icon.ChevronDown}
                      size={14}
                      color={colors.gray.secondary}
                      style={{marginLeft: 4, marginRight: 4, marginTop: 2}}
                    />
                  </ReactSelectComponents.DropdownIndicator>
                );
              },
            }),
        Option: showDescriptionInOption ? OptionWithDescription : Option,
        Group: (props) => {
          const allOptions = props.selectProps.options;
          const isFirstGroup = _.get(props, 'data.label') === _.get(allOptions, '0.label');
          const isLastGroup =
            _.get(props, 'data.label') === _.get(allOptions, `${allOptions.length - 1}.label`);

          return (
            <div id={'revertGlobalStyles'}>
              <GroupContainer>
                {!isFirstGroup && <GroupDivider />}
                <Space height={12} />
                <ReactSelectComponents.Group {...props} />
                {!isLastGroup && <Space height={4} />}
              </GroupContainer>
            </div>
          );
        },
        ...components,
      }}
      styles={{
        container: (current, state) => ({
          ...current,
          pointerEvents: 'auto',
        }),
        control: (current, state) => {
          // Use containerStyleOverride instead of style if you want to override all the default styling
          const baseStyle = containerStyleOverride || current;
          return {
            ...baseStyle,
            '&:hover': {
              borderColor: disabled
                ? INPUT_BORDER_COLOR
                : state.isFocused
                ? colors.blue.interactive
                : colors.blue.hover,
            },
            ...(containerStyleOverride ? {} : {...controlStyle, ...style}),
            ...(state.isFocused ? {borderColor: colors.blue.interactive} : {}),
            ...(isDiscreet ? {...getDiscreetControlStyle({state, placeholder, isHovered})} : {}),
            'pointerEvents': state.isDisabled ? 'none' : 'auto',
            'cursor': disabled ? 'default' : 'text',

            // Custom 'required' feature to change the background-color.
            'backgroundColor': getBackgroundColor({
              disabled,
              required,
              backgroundColor,
            }),
          };
        },
        valueContainer: (current) => ({
          ...(valueContainerStyle || current),
          ...(isDiscreet ? {paddingLeft: '0px'} : {}),
        }),
        placeholder: (current) => ({
          ...current,
          ...defaultPlaceholderStyle,
          ...(isDiscreet ? {marginLeft: '0px'} : {}),
          fontSize,
          ...placeholderStyle,
        }),
        option: (current) => ({
          ...current,
          ...defaultOptionStyle,
          fontSize,
          ...optionStyle,
        }),
        menu: (current) => {
          const styles = {
            ...current,
            display: 'block',
            overflow: 'hidden',
            ...(isJoinedList
              ? {
                  marginTop: 0,
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                }
              : {}),
          };
          return styles;
        },
        menuList: (current) => ({
          ...current,
          display: 'block',
          ...(isJoinedList
            ? {
                paddingTop: 0,
                paddingBottom: 0,
              }
            : {}),
          ...menuListStyle,
        }),
        singleValue: (current, state) => ({
          ...current,
          ...singleValueStyle,
          color: getSingleValueColor({state}),
          ...selectionStyle,
          ...(isDiscreet ? {marginLeft: '0px'} : {}),
          fontSize,
        }),
        dropdownIndicator: (current, state) => ({
          ...current,
          ...(isDiscreet
            ? {
                padding: '0px',
                display: 'none',
                ...(isHovered || state.isFocused ? {display: 'flex'} : {}),
              }
            : {}),
        }),
        indicatorSeparator: (current) => ({
          ...current,
          ...(isDiscreet ? {display: 'none'} : {}),
        }),
        input: (current, state) => ({
          ...(inputStyle
            ? {position: !state.value ? 'absolute' : 'relative', ...inputStyle}
            : current),
          width: '100%',
          input: {
            // On mobile, setting the width of the inner input to 100% enables copy paste.
            // On desktop, this causes problems by preventing the text from filling the
            // entire width of the input.
            width: responsive.mobile ? '100% !important' : null,
            textAlign: 'left',
            fontFamily: 'Avenir',
            fontWeight: 500,
          },
        }),
      }}
    />
  );
};

DropdownInput.createFilter = createFilter;
DropdownInput.OptionWithDescription = OptionWithDescription;
DropdownInput.OptionWithDescriptionOnRight = OptionWithDescriptionOnRight;
DropdownInput.OptionContainer = ReactSelectComponents.Option;
DropdownInput.SingleValueContainer = ReactSelectComponents.SingleValue;
DropdownInput.Option = Option;
DropdownInput.OptionLabel = Label;
DropdownInput.OptionSecondaryLabel = SecondaryLabel;
DropdownInput.GroupContainer = GroupContainer;
DropdownInput.GroupDivider = GroupDivider;
DropdownInput.GroupLabel = GroupLabel;
DropdownInput.getBackgroundColor = getBackgroundColor;

// --------------------------------------------------
// Props
// --------------------------------------------------
DropdownInput.propTypes = {
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isHovered: PropTypes.bool,
  isDiscreet: PropTypes.bool,
  isPortaled: PropTypes.bool,
  isSingleOptionSelected: PropTypes.bool,
  tabSelectsValue: PropTypes.bool,
  selectionStyle: PropTypes.object,
  valueContainerStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  placeholderStyle: PropTypes.object,
  menuListStyle: PropTypes.object,
  optionStyle: PropTypes.object,
  fontSize: PropTypes.number,
  menuPlacement: PropTypes.string,
  controlShouldRenderValue: PropTypes.bool,
};

DropdownInput.defaultProps = {
  isClearable: false,
  isSearchable: true,
  isHovered: false,
  isDiscreet: false,
  isPortaled: false,
  isSingleOptionSelected: false,
  tabSelectsValue: false,
  selectionStyle: {},
  valueContainerStyle: null,
  inputStyle: null,
  placeholderStyle: {},
  menuListStyle: {},
  optionStyle: {},
  fontSize: 14,
  menuPlacement: 'bottom',
  controlShouldRenderValue: true,
};

export default DropdownInput;
