/* eslint-disable react-hooks/exhaustive-deps */

// Libraries
import {useEffect, useRef} from '@supermove/hooks';

/**
 * useBeforeRefreshEffect() hook, runs an effect before the page refreshes,
 * includes browser forward and back buttons.
 *
 * Usage:
 * The first argument is the effect that you want to run before refresh.
 * The second (optional) argument is an object of props that you want to use in your effect.
 *
 * useBeforeRefreshEffect(({stuff}) => {
 *   console.log("This is the value of stuff when the page refreshes!", stuff)
 * }, {stuff})
 *
 */
const useBeforeRefreshEffect = (effect, props = {}) => {
  // Track the given props with this ref so that we can access them when the effect runs
  const ref = useRef(props);
  useEffect(() => {
    ref.current = props;
  }, [props]);

  // NOTE(cooper): React does not run cleanup logic on page refreshes,
  // so we add that manually here with the "visibilitychange" event listener.
  // We could also use "beforeunload" but that is not supported on some mobile browsers.
  useEffect(() => {
    // Handles browser forward/back buttons
    const popStateHandler = () => {
      effect(ref.current);
    };

    // Handles browser refresh button
    const visibilityChangeHandler = () => {
      if (document.visibilityState === 'hidden') {
        effect(ref.current);
      }
    };

    window.addEventListener('popstate', popStateHandler);
    window.addEventListener('visibilitychange', visibilityChangeHandler);

    return () => {
      window.removeEventListener('popstate', popStateHandler);
      window.removeEventListener('visibilitychange', visibilityChangeHandler);
    };
  }, [effect]);
};

export default useBeforeRefreshEffect;
