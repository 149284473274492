// Libraries
import {SwitchRouter, SwitchView, createNavigator} from '@react-navigation/core';
import React from 'react';

// Wraps the current scene with `component` which is defined in the config.
// This allows us to have a wrapper component with a permanent Sidebar that encapsulates
// the SwitchView routes.
const createNavigationView = ({component: Component}) => (props) => (
  <Component>
    <SwitchView {...props} />
  </Component>
);

const createSidebarNavigator = (routes, config) => {
  const {sidebar, ...switchConfig} = config;
  const NavigationView = createNavigationView(sidebar);
  const Router = SwitchRouter(routes, switchConfig);

  return createNavigator(NavigationView, Router, {});
};

export default createSidebarNavigator;
