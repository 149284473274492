// Libraries
import _ from 'lodash';

const Json = {
  // Inject JSON functions like 'parse' and 'stringify'.
  parse: JSON.parse,
  stringify: JSON.stringify,

  toForm: (string) => {
    return Json.toObject(string);
  },
  toMutation: (object) => {
    return Json.toString(object);
  },
  toObject: (string) => {
    return Json.toCamelCaseKeys(Json.parse(string));
  },
  toString: (object) => {
    return Json.stringify(Json.toSnakeCaseKeys(object));
  },
  mapKeys: (object, mapper) => {
    if (Array.isArray(object)) {
      return _.map(object, (value) => Json.toCamelCaseKeys(value));
    } else if (_.isPlainObject(object)) {
      const reducer = (result, value, key) => ({
        ...result,
        [mapper(key)]: Json.toCamelCaseKeys(value),
      });
      return _.reduce(object, reducer, {});
    }
    return object;
  },
  mapKeysRecursive: (object, mapper) => {
    if (Array.isArray(object)) {
      return _.map(object, (value) => Json.mapKeysRecursive(value, mapper));
    } else if (_.isPlainObject(object)) {
      const reducer = (result, value, key) => ({
        ...result,
        [mapper(key)]: Json.mapKeysRecursive(value, mapper),
      });
      return _.reduce(object, reducer, {});
    }
    return object;
  },
  toCamelCaseKeys: (object) => {
    return Json.mapKeys(object, (key) => _.camelCase(key));
  },
  toSnakeCaseKeys: (object) => {
    return Json.mapKeys(object, (key) => _.snakeCase(key));
  },
  toSnakeCaseKeysRecursive: (object) => {
    return Json.mapKeysRecursive(object, (key) => _.snakeCase(key));
  },
  toUpperCaseKeys: (object) => {
    return Json.mapKeys(object, (key) => _.toUpper(key));
  },
};

export default Json;
