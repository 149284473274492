const Phone = {
  display: (phoneNumber) => {
    if (!phoneNumber) {
      return '';
    } else {
      return (
        `(${phoneNumber.substring(0, 3)})` +
        ` ` +
        `${phoneNumber.substring(3, 6)}` +
        `-` +
        `${phoneNumber.substring(6, 10)}` +
        `${phoneNumber.substring(10)}`
      );
    }
  },

  createUrl: (phoneNumber) => {
    return `tel:${phoneNumber}`;
  },

  strip: (phoneNumber) => {
    return phoneNumber.replace(/\D/g, '');
  },
};

export default Phone;
