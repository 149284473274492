// Libraries
import {useEffect} from 'react';

const useZendeskWebWidget = ({zendeskWebWidgetKey, options, isHidden}) => {
  useEffect(() => {
    if (!isHidden) {
      const zendeskInitScript = document.createElement('script');
      zendeskInitScript.id = 'ze-snippet';
      zendeskInitScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskWebWidgetKey}`;
      zendeskInitScript.async = true;
      document.body.appendChild(zendeskInitScript);
      window.zESettings = {...options};
      return () => {
        document.body.removeChild(zendeskInitScript);
      };
    }
  }, [zendeskWebWidgetKey, options, isHidden]);
};

export default useZendeskWebWidget;
