// Libraries
import _ from 'lodash';

export const getActiveRoute = (state) => {
  if (!state) {
    return null;
  }
  const route = _.get(state, `routes.${state.index}`);

  // Handle nested routes.
  if (route.routes) {
    return getActiveRoute(route);
  }
  return route;
};

export const getActiveNavigation = (navigation) => {
  const {state} = navigation;
  const key = _.get(state, `routes.${state.index}.key`);
  if (key) {
    return getActiveNavigation(navigation.getChildNavigation(key));
  }
  return navigation;
};

export const getActiveOptions = (navigation) => {
  const activeNavigation = getActiveNavigation(navigation);
  const parentNavigation = activeNavigation.dangerouslyGetParent();
  return parentNavigation.router.getScreenOptions(activeNavigation);
};
