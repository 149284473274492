// Libraries
import {DocumentNode} from 'graphql';
import {BaseMutationOptions, OperationVariables, MutationTuple} from 'react-apollo';

import {useFormErrors, useMutation, useMutationCallbacks} from '@supermove/hooks';

import {Form, MutationResponse, MutationError} from './types';

type Args<FormValues, Results, Variables> = {
  form: Form<FormValues>;
  mutation: DocumentNode;
  refetchQueries?: BaseMutationOptions['refetchQueries'];
  variables: Variables;
  onSuccess: (results: Results) => void;
  onError: (errors: MutationError[]) => void;
  client?: BaseMutationOptions['client'];
};

const useFormMutation = <
  FormValues = Record<string, any>,
  Results = Record<string, any>,
  Variables = OperationVariables
>({
  form,
  mutation,
  refetchQueries,
  variables,
  onSuccess,
  onError,
  client,
}: Args<FormValues, Results, Variables>): {
  submitting: boolean;
  handleSubmit: MutationTuple<MutationResponse<Results>, Variables>[0];
} => {
  const [handleSubmit, {loading, data}] = useMutation(mutation, {
    refetchQueries,
    variables,
    client,
  });
  // Trigger validation errors on the form.
  useFormErrors({form, data});
  // Trigger callbacks when the data field changes.
  useMutationCallbacks({data, onSuccess, onError});

  return {
    submitting: loading,
    handleSubmit,
  };
};

export default useFormMutation;
