// Libraries
import PropTypes from 'prop-types';
import React from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

// Supermove
import {useDraggableInPortal} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {uuid} from '@supermove/utils';

// App
import Icon from '../Icon';
import Space from '../Space';
import Styled from '../Styled';

const ItemContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  flex: 1;
  zIndex: ${({index}) => 5000 - index};
`;

const DraggableIcon = () => {
  return <Icon color={colors.gray.secondary} size={Icon.Sizes.Large} source={Icon.GripVertical} />;
};

const DragAndDropList = ({
  isReordering,
  onReorder,
  children,
  DraggableIconComponent,
  spaceBetweenItems,
  scrollRef,
  droppableStyle,
  itemContainerStyle,
  indexOfEdit,
  isDisabled,
  isDisabledWithVisibleIcons,
}) => {
  const renderDraggable = useDraggableInPortal();

  return (
    <DragDropContext
      onDragEnd={(result) => {
        if (!result.source || !result.destination) {
          return;
        }
        onReorder({fromIndex: result.source.index, toIndex: result.destination.index});
      }}
    >
      <Droppable droppableId={uuid()}>
        {(droppableProvided) => (
          <div
            ref={(element) => {
              droppableProvided.innerRef(element);
              if (scrollRef) {
                scrollRef.current = element;
              }
            }}
            {...droppableProvided.droppableProps}
            style={{
              display: 'inline-block',
              overflowY: 'auto',
              ...droppableStyle,
            }}
          >
            {React.Children.map(children, (item, index) => {
              const isEditingOrReordering = indexOfEdit || isReordering;
              const isEditingAnotherItem = indexOfEdit && indexOfEdit !== index;
              return (
                <React.Fragment key={item.key}>
                  <Draggable
                    isDragDisabled={
                      isDisabled || isEditingOrReordering || isDisabledWithVisibleIcons
                    }
                    draggableId={item.key}
                    index={index}
                  >
                    {renderDraggable((draggableProvided) => (
                      <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
                        <ItemContainer
                          index={index}
                          style={{
                            marginBottom: spaceBetweenItems,
                            ...itemContainerStyle,
                            ...(isEditingAnotherItem || isDisabledWithVisibleIcons
                              ? {opacity: 0.5}
                              : {}),
                          }}
                        >
                          {!isDisabled && (
                            <div {...draggableProvided.dragHandleProps}>
                              {DraggableIconComponent}
                            </div>
                          )}
                          <Space width={10} />
                          {item}
                        </ItemContainer>
                      </div>
                    ))}
                  </Draggable>
                </React.Fragment>
              );
            })}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DragAndDropList.DraggableIcon = DraggableIcon;

// --------------------------------------------------
// Props
// --------------------------------------------------
DragAndDropList.propTypes = {
  onReorder: PropTypes.func,
  children: PropTypes.array.isRequired,
  DraggableIconComponent: PropTypes.element,
  isReordering: PropTypes.bool,
  spaceBetweenItems: PropTypes.number,
  scrollRef: PropTypes.object,
  droppableStyle: PropTypes.object,
  itemContainerStyle: PropTypes.object,
  indexOfEdit: PropTypes.number,
  isDisabled: PropTypes.bool,
  isDisabledWithVisibleIcons: PropTypes.bool,
};

DragAndDropList.defaultProps = {
  onReorder: () => {},
  DraggableIconComponent: <DraggableIcon />,
  isReordering: false,
  spaceBetweenItems: 0,
  scrollRef: null,
  droppableStyle: null,
  itemContainerStyle: null,
  indexOfEdit: null,
  isDisabled: false,
  isDisabledWithVisibleIcons: false,
};

export default DragAndDropList;
