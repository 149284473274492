// Libraries
import {StackRouter, SwitchView, createNavigator} from '@react-navigation/core';

// For web, we create a custom `createStackNavigator` function that uses a StackRouter
// with a SwitchView (no transitions).
const createStackNavigator = (routes, config = {}) => {
  const router = StackRouter(routes, config);
  const Navigator = createNavigator(SwitchView, router, config);
  return Navigator;
};

export default createStackNavigator;
