// Libraries
import React from 'react';

// App
import {colors, Typography} from '@supermove/styles';

// Components
import Space from '../Space';
import Styled from '../Styled';

const Touchable = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  padding: 4px;
`;

const DisabledContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  padding: 4px;
`;

const Circle = Styled.View`
  margin: ${(props) => (props.isSelected ? '2px' : '0px')};
  width: ${(props) => (props.isSelected ? '12px' : '16px')};
  height: ${(props) => (props.isSelected ? '12px' : '16px')};
  background-color: ${(props) =>
    props.disabled
      ? colors.gray.border
      : props.isSelected
      ? props.circleColor
      : colors.transparent};
  border-radius: ${(props) => (props.isSelected ? '6px' : '8px')};
`;

const Outline = Styled.View`
  border-width: 1px;
  border-color: ${(props) => props.color};
  border-radius: 15px;
`;

const Label = Styled.Text`
  ${(props) => (props.labelstyle ? props.labelstyle : Typography.Body2)};
  color: ${(props) => (props.disabled ? colors.gray.tertiary : colors.black)};
`;

const RadioInput = ({
  disabled,
  isSelected,
  isSelectedNonTouchable,
  color,
  circleColor,
  value,
  label,
  labelstyle,
  touchableStyle,
  outlineStyle,
  onSelect,
  children,
}) => {
  // NOTE: DisabledContainer is a workaround for our payengine iframe inputs
  // which do not interact well with the button touch events. The touch events
  // on the button prevent the iframe inputs from being focused.
  const Container = isSelectedNonTouchable && isSelected ? DisabledContainer : Touchable;
  return (
    <Container disabled={disabled} onPress={() => onSelect(value)} style={touchableStyle}>
      <Outline color={disabled ? colors.gray.tertiary : color} style={outlineStyle}>
        <Circle
          disabled={disabled}
          isSelected={isSelected}
          color={disabled ? colors.gray.tertiary : color}
          circleColor={circleColor || color}
        />
      </Outline>
      <Space width={8} />
      <Label disabled={disabled} labelstyle={labelstyle}>
        {label}
      </Label>
      {children}
    </Container>
  );
};

export default RadioInput;
