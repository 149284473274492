/**
 * Used with react-navigation, this module logs route changes to analytics services.
 */

// Libraries
import _ from 'lodash';

// Supermove
import {useEffect, useTrackEvent} from '@supermove/hooks';

const getEventName = ({pageName}) => {
  // Only append the word "Page" if it's not already in the string
  // All new routes should have Page in the name so we should eventually be able to remove this check
  if (!pageName.endsWith('Page')) {
    return `Viewed ${pageName} Page`;
  }

  return `Viewed ${pageName}`;
};

const useTrackPageview = ({routeName}) => {
  // Fetch properties from the route.
  const pageName = _.startCase(routeName);
  const eventName = getEventName({pageName});
  const {trackEvent} = useTrackEvent({
    eventName,
    eventType: 'Pageview',
    pageName,

    // TODO(mark): Add route -> url tracking so that we can record the current url for every event.
    url: '',
  });

  // Whenever this route changes, we want to log another event.
  useEffect(() => trackEvent(), [trackEvent]);
};

export default useTrackPageview;
