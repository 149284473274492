const EnvironmentVariables = {
  get: (key) => {
    // NOTE(mark): Returns the GATSBY prefixed variables which are the only
    // environment variables that are exposed on the client side javascript.
    // We have to use a switch/case here because of how gatsby compiles the
    // env variables at build time.
    switch (key) {
      case 'APP_BUILD':
        return process.env.GATSBY_APP_BUILD;
      case 'APP_ENV':
        return process.env.GATSBY_APP_ENV;
      case 'APP_NAME':
        return process.env.GATSBY_APP_NAME;
      case 'APP_VERSION':
        return process.env.GATSBY_APP_VERSION;
      case 'COHERE_KEY':
        return process.env.COHERE_KEY;
      case 'RENDER_GIT_COMMIT':
        return process.env.RENDER_GIT_COMMIT;
      case 'SENTRY_TRACES_SAMPLE_RATE':
        return process.env.GATSBY_SENTRY_TRACES_SAMPLE_RATE;
      case 'SENTRY_URL':
        return process.env.GATSBY_SENTRY_URL;
      case 'SENTRY_ENABLE_SESSION_REPLAY':
        return process.env.GATSBY_SENTRY_ENABLE_SESSION_REPLAY === 'true';
      case 'NODE_ENV':
        return process.env.NODE_ENV;
      case 'USERFLOW_KEY':
        return process.env.USERFLOW_KEY;
      case 'SHOW_MAINTENANCE_PAGE':
        return process.env.SHOW_MAINTENANCE_PAGE === 'true';
      case 'ZENDESK_WEB_WIDGET_KEY':
        return process.env.ZENDESK_WEB_WIDGET_KEY;
      case 'LAUNCH_DARKLY_CLIENT_KEY':
        return process.env.LAUNCH_DARKLY_CLIENT_KEY;
      case 'LAUNCH_DARKLY_MOBILE_KEY':
        return process.env.LAUNCH_DARKLY_MOBILE_KEY;
      default:
        return;
    }
  },
};

export default EnvironmentVariables;
