// Libraries
import _ from 'lodash';

// Supermove
import {useNavigationDOM, useState} from '@supermove/hooks';
import {Datetime, URL} from '@supermove/utils';

const useUrlFilters = ({filterKeys, filtersForReset, filtersForUpdate, onUpdate, getRoute}) => {
  const {navigator, params: currentParams} = useNavigationDOM();
  const [resetKey, setResetKey] = useState(0);

  const getFilters = () => {
    return _.reduce(
      filterKeys,
      (result, key) => ({
        ...result,
        [key]: _.get(currentParams, key),
      }),
      {},
    );
  };

  const handleUpdate = (updatedFilters, isPushRoute) => {
    const currentFilters = getFilters();
    const navigate = _getNavigate({navigator, isPushRoute});

    navigate(
      URL.getUrlFromVariables(`${getRoute()}`, {
        ...currentFilters,
        ...(filtersForUpdate || {}),
        ...updatedFilters,
      }),
    );

    onUpdate && onUpdate();
  };

  const handleUpdateDate = ({date, dateKey}, isPushRoute) => {
    handleUpdate({[dateKey]: date ? Datetime.convertToDate(date) : ''}, isPushRoute);
  };

  const handleReset = (customFilters, isPushRoute) => {
    const resetFilters = _.reduce(
      filterKeys,
      (result, key) => ({
        ...result,
        [key]: null,
      }),
      {},
    );
    const navigate = _getNavigate({navigator, isPushRoute});

    navigate(
      URL.getUrlFromVariables(`${getRoute()}`, {
        ...resetFilters,
        ...(filtersForReset || {}),
        ...(customFilters || {}),
      }),
    );

    setResetKey(resetKey + 1);
  };

  const getInputValueForDate = ({dateKey}) => {
    const urlDateValue = currentParams[dateKey];
    const isValidDate = Datetime.isValidDateString(urlDateValue);
    if (isValidDate) {
      return Datetime.fromDate(urlDateValue);
    }
    return '';
  };

  const getFilterCount = ({filterKeys: selectKeys} = {}) => {
    const filters = _.reduce(
      selectKeys || filterKeys,
      (result, key) => [...result, _.get(currentParams, key)],
      [],
    );

    return _.compact(filters).length;
  };

  const getFilterCountLabel = ({filterKeys: selectKeys} = {}) => {
    const filterCount = getFilterCount({filterKeys: selectKeys});

    return `Filters${filterCount ? ` (${filterCount})` : ''}`;
  };

  const get = (filterKey) => {
    return _.get(currentParams, filterKey);
  };

  return {
    // Handlers
    handleUpdate, // Set any amount of filters
    handleUpdateDate, // For updating a Datetime (eg. from DateInput)
    handleReset, // Can take filters to set
    resetKey,

    // Input Helpers
    getInputValueForDate, // Transform date string to Datetime

    // Getters
    get, // Takes only a key as an argument
    getFilters, // Can use for query variables
    getFilterCount, // Count of applied filters
    getFilterCountLabel, // eg. "Filters (2)"
  };
};

const _getNavigate = ({navigator, isPushRoute}) => {
  if (isPushRoute) {
    return navigator.push;
  }
  return navigator.replace;
};

export default useUrlFilters;
