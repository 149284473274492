// Libraries
import PropTypes from 'prop-types';
import React from 'react';
import {Toaster} from 'react-hot-toast';

// Supermove
import {useResponsive} from '@supermove/hooks';

const getResponsivePosition = ({responsive}) => {
  if (responsive.mobile) {
    return ToastContainer.Position.BOTTOM_LEFT;
  }
  return ToastContainer.Position.BOTTOM_CENTER;
};

const ToastContainer = ({position, duration}) => {
  const responsive = useResponsive();
  return (
    <Toaster
      position={position || getResponsivePosition({responsive})}
      gutter={8}
      containerStyle={
        responsive.mobile
          ? {
              // There is is some react-hot-toast styling that pads the left by 10px, so we add an
              // additional 6px for a 16px gutter for mobile. Then the styling on the toast needs
              // to subtract 32px from the viewport width for there to be a 16px gutter on the right.
              left: 6,
              bottom: 12,
            }
          : {
              bottom: 64,
            }
      }
      toastOptions={{
        duration,
        style: {
          padding: 0,
          backgroundColor: 'transparent',
          boxShadow: '0 0 0 0',
        },
      }}
    />
  );
};

ToastContainer.Position = {
  TOP_LEFT: 'top-left',
  TOP_CENTER: 'top-center',
  TOP_RIGHT: 'top-right',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_CENTER: 'bottom-center',
  BOTTOM_RIGHT: 'bottom-right',
};

// --------------------------------------------------
// Props
// --------------------------------------------------
ToastContainer.propTypes = {
  position: PropTypes.string,
  duration: PropTypes.number,
};

ToastContainer.defaultProps = {
  position: null,
  duration: 5000,
};

export default ToastContainer;
