// Supermove
import {useState} from '@supermove/hooks';

const useTabs = ({initialIndex, onChangeIndex}) => {
  const [selectedIndex, setSelectedIndex] = useState(initialIndex);
  const handleChangeIndex = (index) => {
    setSelectedIndex(index);
    if (onChangeIndex) {
      onChangeIndex(index);
    }
  };

  return {
    selectedIndex,
    setSelectedIndex,
    handleChangeIndex,
  };
};

export default useTabs;
