// Libraries
import {MockedProvider} from '@apollo/client/testing';
import gql from 'graphql-tag';
import {
  ApolloProvider,
  Query,
  Mutation,
  useLazyQuery,
  useSubscription,
  useApolloClient,
} from 'react-apollo';

// App
import {
  createCache,
  createClient,
  createAuthenticationMiddleware,
  createErrorMiddleware,
  createGraphQLMiddleware,
  createHeadersMiddleware,
  createRetryMiddleware,
  RestContext,
} from './apollo';
import usePersistCache from './usePersistCache';
import {useQuery, useMutation, useApolloContextWithHeaders} from './wrappers';

gql.query = `
  __typename
`;

gql.errors = `
  errors {
    field
    message
  }
`;

export {
  // Apollo
  ApolloProvider,
  Query,
  Mutation,
  MockedProvider,
  useQuery,
  useLazyQuery,
  useMutation,
  useSubscription,
  useApolloClient,
  // GraphQL
  gql,
  // Client
  createCache,
  createClient,
  // Hooks
  useApolloContextWithHeaders,
  usePersistCache,
  // Middleware
  createAuthenticationMiddleware,
  createErrorMiddleware,
  createGraphQLMiddleware,
  createHeadersMiddleware,
  createRetryMiddleware,
  RestContext,
};
