// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Loading, Query, Styled} from '@supermove/components';
import {gql, RestContext} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';

const Indicator = Styled.Loading`
  margin-top: 20px;
`;

// UpdateChecker for web checks against a /version.json file generated at build-time, instead of
// hitting our API
const UpdateChecker = ({showLoading, name, buildNumber, pollInterval, children}) => {
  const [isSkipped, setIsSkipped] = useState(false);

  return (
    <Query
      fetchPolicy={'network-only'}
      pollInterval={pollInterval}
      query={UpdateChecker.query}
      context={RestContext}
    >
      {({loading, data, error}) => (
        <Loading
          loading={loading}
          as={showLoading ? <Indicator size={'small'} color={colors.gray.secondary} /> : null}
        >
          {() =>
            children({
              isSkipped,
              isVisible: data && data.version.app_build !== buildNumber,
              isRequired: false,
              onSkip: () => setIsSkipped(true),
            })
          }
        </Loading>
      )}
    </Query>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
UpdateChecker.propTypes = {
  showLoading: PropTypes.bool,
};

UpdateChecker.defaultProps = {
  showLoading: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateChecker.query = gql`
  query UpdateCheckerWeb {
    version @rest(type: "Version", path: "version.json", endpoint: "static") {
      app_build
      app_version
      commit
    }
  }
`;

export default UpdateChecker;
